import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";


@Injectable({
    providedIn: 'root'
  })
export class OdemeService
{
    private ODEME_PATH = "/odeme";

    constructor(private apiService: ApiService)
    {}
    
    odemeicin3DSorgusuYap(odemeBilgileri:any):Observable<any>{
      //  console.log("service.ts -> odemeicin3DSorgusuYap() calisti");
        return this.apiService.post(this.ODEME_PATH,odemeBilgileri).pipe(map(
            result=>{
              if (result!=null)
              {
              //  console.log('result dolu');
                return result;
              }else {
               // console.log('result null :');
               // console.log(result);
                return {};
              }
            }
          ));
    }

}