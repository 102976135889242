import { AbstractControl, ValidatorFn } from "@angular/forms";

export class CommonValidators {

  //Sadece sayı girişine izin verir
  keyPressNumbers(event: { which: any; keyCode: any; preventDefault: () => void; }) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressAlfabe(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123) ||
      charCode == 231 || charCode == 199 || charCode == 305 || charCode == 304 || charCode == 287 ||
      charCode == 286 || charCode == 252 || charCode == 220 || charCode == 246 || charCode == 214 ||
      charCode == 351 || charCode == 350 || charCode == 32) {
      return true;
    } else {
      return false;
    }
  }

  // keyPressNumbers(event) {
  //   var charCode = (event.which) ? event.which : event.keyCode;
  //   // Only Numbers 0-9
  //   if ((charCode < 48 || charCode > 57)) {
  //     event.preventDefault();
  //     return false;
  //   } else {
  //     return true;
  //   }
  // }

//   static tcknFormatKontrolValidator(): ValidatorFn {
//     return (control: AbstractControl): { [key: string]: boolean } | null => {
//       let error;
//       const tcknVerisi = control.value;
// debugger
//       if (tcknVerisi.length === 0) {
//         return null;
//       }
//       else if (tcknVerisi.length !== 11) {
//         return { 'tcnk11karakter': true };
//       }
//       else {
//         if (Number(tcknVerisi[0]) !== 0) {
//           let toplam: number = 0;
//           for (let i = 0; i < 10; i++) {
//             toplam = toplam + Number(tcknVerisi[i]);
//           }
//           if (toplam % 10 == Number(tcknVerisi[10])) {
//             let ilk: number = (Number(tcknVerisi[0]) + Number(tcknVerisi[2]) + Number(tcknVerisi[4]) + Number(tcknVerisi[6]) + Number(tcknVerisi[8])) * 7;
//             let ikinci: number = Number(tcknVerisi[1]) + Number(tcknVerisi[3]) + Number(tcknVerisi[5]) + Number(tcknVerisi[7]);
//             if ((ilk - ikinci) % 10 == Number(tcknVerisi[9])) {
//               return null;
//             }
//             else {
//                 return { 'tcknFormatHatasi': true };
//             }
//           }
//           else {
//             return { 'tcknFormatHatasi': true };
//           }
//         }
//         else {
//           return { 'tcnk0IleBaslamaz': true };
//         }
//       }
//     };
//   }



  static yasKontrolValidator(yas: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      var bugun = new Date();
      var dogumGunu = new Date(control.value);
      var age = bugun.getFullYear() - dogumGunu.getFullYear();
      var m = bugun.getMonth() - dogumGunu.getMonth();
      if (m < 0 || (m === 0 && bugun.getDate() < dogumGunu.getDate())) {
        age--;
      }

      if (age < yas) {
        return { 'yasKontrol': true };
      }
      return null;
    };
  }

  // export function AgeValidator(control: AbstractControl): { [key: string]: boolean } | null {
  //   if (control.value > 18) {
  //     return { 'age': true };
  //   }
  //   return null;
  // }

}

//Ortak patternler burada eklenecektir
export enum ValidRegex {
  //Email tip kontrolü
  emailRegex = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  //Sadece harf girişi yaptırır
  harfRegex = '^[A-Za-zıİüÜöÖşŞğĞÇç ]+$',
  //IP format kontrolü
  ipRegex = '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)',
}
