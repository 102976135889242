// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

//TODO: sondaki api silinmeli olabilir
export const environment = {
  production: false,

  //production için
  API_BASE_PATH: 'https://tckimlikeimzaws.turktrust.com.tr/api'

  //test için
  //API_BASE_PATH: 'http://localhost:8080/api'

  //KULLANILMIYOR
  //API_BASE_PATH: "http://remsign.e117.com.tr:8080/api",
  //API_BASE_PATH: 'http://tckimlikeimza.turktrust.com.tr:8080/api'
  //API_BASE_PATH: 'http://192.168.10.232:8080/api'
};