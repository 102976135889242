import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-online-odeme-sonuc',
  templateUrl: './online-odeme-sonuc.component.html',
  styleUrls: ['./online-odeme-sonuc.component.css']
})
export class OnlineOdemeSonucComponent implements OnInit {
  sonuc: string;
  ori: string;
  bo: string;
  vk: string;
  bom: string;
  vkm: string;
  fb: string;
  sye: string;

  gosterilecekSonuc: string;
  siparisNumarasiGosterilsinMi: boolean = false;
  bildirimMesaji: string;
  bildirimGosterilsin: boolean = false;

  constructor(private activeRoute: ActivatedRoute, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {
    //console.log(activeRoute.snapshot.queryParams);
    //paramlar degeişmelerine subscribe ol, verdiğim mesajı sonra yazar.
    activeRoute.queryParams.subscribe(params => {
      //debugger

      this.bildirimMesaji = 'Başvurunuz başarıyla tamamlanmıştır. Nüfus Müdürlüklerinde bulunan Nüfusmatiklerde, e-imzanızı TC Kimlik kartınıza yükleyebilirsiniz.';

      this.ori = params['ori'];
      this.bo = params['bo'];
      this.vk = params['vk'];
      this.bom = params['bom'];
      this.vkm = params['vkm'];
      this.fb = params['fb'];
      this.sye = params['sye'];

      // console.log(this.bo);
      //  console.log(this.bk);


      if (this.bo=="true" ) {
        
        //banka ödemesi gerceklestıgınden her türlü sipariş numarasaını gösteriyorum. Yani bn numarasını. Loglardan bakılabilir.
        this.siparisNumarasiGosterilsinMi = true;
        if (this.vk=="true") {
          this.bildirimGosterilsin = true;
          this.gosterilecekSonuc = this.bom;
        }
        else {       
          this.bildirimGosterilsin = false;
          this.gosterilecekSonuc = this.bom + " , fakat, " + this.vkm + ". Teknik destek isteyiniz. ";     
        }
      }
      else {
        this.bildirimGosterilsin = false;
        this.siparisNumarasiGosterilsinMi = false;
        this.gosterilecekSonuc = this.bom +' ('+ this.sye+')';
      }
    });




  }
  ngOnInit(): void {
    // debugger
    sessionStorage.clear();
  }

}
