import { HttpHeaders, HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    httpOptions = {
        headers: new HttpHeaders(
            { 'Content-Type': 'application/json' })
    }

    constructor(private http: HttpClient) {
    }

    // get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    //     console.log('api.service-> get');
    //     console.log(path);
    //     console.log("params: " +JSON.stringify(params));
    //     console.log("path:" + environment.API_BASE_PATH + path)
    //     return this.http.get(environment.API_BASE_PATH + path, {params}).pipe(catchError(this.formatError));
    //   }

    post(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        //console.log('api.service-> post');
        //console.log("jsonparam: " + JSON.stringify(params));
        return this.http.post(environment.API_BASE_PATH + path, JSON.stringify(params), this.httpOptions);
    }

    // private formatError(error: any) {
    //     return of(error.error)
    // }
}