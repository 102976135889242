import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { param } from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { OdemeService } from '../../services/odeme.service';
import { CommonValidators, ValidRegex } from '../../common/CommonValidators';
import { OdemeBilgileriDto } from '../../dto/odeme-bilgileri.model';
import { BasvuruBilgileriDto } from '../../dto/basvuru-bilgileri.model';
import { BankaOdemeBilgileriDto } from '../../dto/banka-odeme.model';
import { PlatformLocation } from '@angular/common';
import { MessageComponent } from '../../message/message.component';

@Component({
  selector: 'app-odeme',
  templateUrl: './odeme.component.html',
  styleUrls: ['./odeme.component.css']
})
export class OdemeComponent implements OnInit {

  odemeForm: FormGroup;
  tckn: string;
  adSoyad: string;
  odemeDevam: boolean;
  basvuruSahibi: any;
  aylar: string[] = [];
  yillar: string[] = [];
  seciliYil: number;

  constructor(/*private activeRoute: ActivatedRoute,*/ private router: Router, private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, public commonValidators: CommonValidators, private odemeService: OdemeService,
    private basvuru: BasvuruBilgileriDto, private odemeBilgileri: OdemeBilgileriDto, private bankaOdemeBilgileri: BankaOdemeBilgileriDto, private mesaj: MessageComponent) {
    /* Farklı bir alma metodu
      // sadece bir kereye mahsus,  verdiğim mesajı sonra yazar
      console.log(activeRoute.snapshot.queryParams, 'odeme bilgileri');
  
      // activeRoute.snapshot.queryParams.get('tckn');
      // activeRoute.snapshot.queryParams.get('adsoyad');
  
      // paramlar degeişmelerine subscribe ol, verdiğim mesajı sonra yazar.
      activeRoute.queryParams.subscribe(params => {
        console.log(params, 'odeme bilgileri subscribeli');
        this.tckn = params['tckn'];
        this.adSoyad = params['adSoyad'];
      });
    */

    // Test için. Constructor içindeki basvuru nesnesi injectable haldeyken dolu geliyor.
    // console.log('basvuru ekranindan gelen bilgiler:')
    // console.log(JSON.stringify(this.basvuru));

    //debugger
    //Eğer bir önceki sayfandan direk geliyorsa objeden al, yok F5 vs gibi reload ile geldiyse sessiondan al. Sessionda temiz ise başvuru saytfasına yönlen
    if (sessionStorage == null || sessionStorage.basvuruSahibi == null) {
      //console.log("boş veri olduğundan başvuru sayfasına yönlendiriliyor.");
      this.router.navigate(['basvuru']);
    }
    //JSON parse kısmında hata olursa..
    if (sessionStorage.basvuruSahibi != null) {
      this.basvuruSahibi = JSON.parse(sessionStorage.basvuruSahibi);
      if (this.basvuruSahibi != null) {
        if (this.basvuruSahibi.tckn == null) {
          this.router.navigate(['basvuru']);
        }
      }
    }


    //nesne boş değilse nesneden, boş ise ise sessionkini ver..
    if (this.basvuru.tckn != null && this.basvuru.tckn.trim() != '') {
      this.odemeBilgileri.basvuruBilgileriDto = {
        tckn: this.basvuru.tckn,
        // ad: this.basvuru.ad,
        // soyad: this.basvuru.soyad,
        cepTelefonu: this.basvuru.cepTelefonu,
        eposta: this.basvuru.eposta,
        dogumTarihi: this.basvuru.dogumTarihi,
        sertifikaSuresi: this.basvuru.sertifikaSuresi,
        kartOkuyucu: this.basvuru.kartOkuyucu,
        odenecekTutar: this.basvuru.odenecekTutar,
        faturaBilgileri:
        {
          faturaAdresi: this.basvuru.faturaBilgileri.faturaAdresi,
          faturaIl: this.basvuru.faturaBilgileri.faturaIl,
          kurumsalBasvuru: this.basvuru.faturaBilgileri.kurumsalBasvuru,
          vergiDairesi: this.basvuru.faturaBilgileri.vergiDairesi,
          vergiNumarasi: this.basvuru.faturaBilgileri.vergiNumarasi,
          sirketAdi: this.basvuru.faturaBilgileri.sirketAdi,
          adimaKesilsinMi: this.basvuru.faturaBilgileri.adimaKesilsinMi,
          iletisimAdresi: this.basvuru.faturaBilgileri.iletisimAdresi,
          iletisimIl: this.basvuru.faturaBilgileri.iletisimIl
        },
        basvuruKontrolBilgileri:
        {
          basvuruNumarasi: this.basvuru.basvuruKontrolBilgileri.basvuruNumarasi,
          takipNumarasi: this.basvuru.basvuruKontrolBilgileri.takipNumarasi
        }
      };

    }
    else if (this.basvuruSahibi.tckn != null && this.basvuruSahibi.tckn.trim() != '') {
      this.odemeBilgileri.basvuruBilgileriDto = {
        tckn: this.basvuruSahibi.tckn,
        // ad: this.basvuruSahibi.ad,
        // soyad: this.basvuruSahibi.soyad,
        cepTelefonu: this.basvuruSahibi.cepTelefonu,
        eposta: this.basvuruSahibi.eposta,
        dogumTarihi: this.basvuruSahibi.dogumTarihi,
        sertifikaSuresi: this.basvuruSahibi.sertifikaSuresi,
        kartOkuyucu: this.basvuruSahibi.kartOkuyucu,
        odenecekTutar: this.basvuruSahibi.odenecekTutar,
        faturaBilgileri:
        {
          faturaAdresi: this.basvuruSahibi.faturaBilgileri.faturaAdresi,
          faturaIl: this.basvuruSahibi.faturaBilgileri.faturaIl,
          kurumsalBasvuru: this.basvuruSahibi.faturaBilgileri.kurumsalBasvuru,
          vergiDairesi: this.basvuruSahibi.faturaBilgileri.vergiDairesi,
          vergiNumarasi: this.basvuruSahibi.faturaBilgileri.vergiNumarasi,
          sirketAdi: this.basvuruSahibi.faturaBilgileri.sirketAdi,
          adimaKesilsinMi: this.basvuruSahibi.faturaBilgileri.adimaKesilsinMi,
          iletisimAdresi: this.basvuruSahibi.faturaBilgileri.iletisimAdresi,
          iletisimIl: this.basvuruSahibi.faturaBilgileri.iletisimIl
        },
        basvuruKontrolBilgileri:
        {
          basvuruNumarasi: this.basvuruSahibi.basvuruKontrolBilgileri.basvuruNumarasi,
          takipNumarasi: this.basvuruSahibi.basvuruKontrolBilgileri.takipNumarasi
        }
      };
    }
    else {
      //console.log("Verinin ödeme ekranına hatali geldigi düşünülüyor..")
      this.router.navigate(['basvuru']);
    }

    this.spinner.hide();
  }

  ngOnInit(): void {
    this.odemeDevam = false;
    this.odemeForm = this.formBuilder.group({
      "tckn": [{

        value: this.odemeBilgileri.basvuruBilgileriDto.tckn,
        disabled: true
      }],
      "cekilecekTutar": [{
        value: this.odemeBilgileri.basvuruBilgileriDto.odenecekTutar + ' TL',
        disabled: true
      }],
      // "adSoyad": [{
      //   value: this.odemeBilgileri.basvuruBilgileriDto.ad + ' ' + this.odemeBilgileri.basvuruBilgileriDto.soyad,
      //   disabled: true
      // }],
      "krediKartiNumarasi": [null, [Validators.required, Validators.minLength(16)]],
      // "sonKullanmaTarihiAy": [null, [Validators.required]],
      // "sonKullanmaTarihiYil": [null, [Validators.required]],
      "seciliSonKullanmaTarihiAy": [null, [Validators.required]],
      "seciliSonKullanmaTarihiYil": [null, [Validators.required]],
      "cvv": [null, [Validators.required, Validators.minLength(3)]],
    });

    this.aylar.push("01");
    this.aylar.push("02");
    this.aylar.push("03");
    this.aylar.push("04");
    this.aylar.push("05");
    this.aylar.push("06");
    this.aylar.push("07");
    this.aylar.push("08");
    this.aylar.push("09");
    this.aylar.push("10");
    this.aylar.push("11");
    this.aylar.push("12");

    this.seciliYil = new Date().getFullYear();
    for (let year = this.seciliYil; year <= this.seciliYil + 10; year++) {
      this.yillar.push(year.toString().substring(2));
    }
  }

  get alanlar() {
    return this.odemeForm.controls
  }

  odemeYap() {
    this.spinner.show();
    this.odemeDevam = true;
    //console.log("component.ts -> odemeYap() calisti");

    if (!this.odemeForm.valid) {
      //console.log("valid degil ödeme calisamaz");
      this.spinner.hide();
      return;
    }


    this.odemeBilgileri.cvv = this.odemeForm.controls.cvv.value;
    this.odemeBilgileri.krediKartiNumarasi = this.odemeForm.controls.krediKartiNumarasi.value;
    //this.odemeBilgileri.sonKullanmaTarihiAy = this.odemeForm.controls.sonKullanmaTarihiAy.value;
    //this.odemeBilgileri.sonKullanmaTarihiYil = this.odemeForm.controls.sonKullanmaTarihiYil.value;
    this.odemeBilgileri.sonKullanmaTarihiAy = this.odemeForm.controls.seciliSonKullanmaTarihiAy.value;
    this.odemeBilgileri.sonKullanmaTarihiYil = this.odemeForm.controls.seciliSonKullanmaTarihiYil.value;

    //TODO: aşağıdaki elle set etmeler da odenecek tutar  servisten alinmali?
    this.odemeBilgileri.posTipi = '3D';
    // this.odemeBilgileri.odenecekTutar = "100";
    this.odemeBilgileri.bankaSecimi = 'GARANTI';


    this.odemeService.odemeicin3DSorgusuYap(this.odemeBilgileri).subscribe(
      (response) => {
        //console.log("component.ts -> odemeService -> odemeYap() calisti");
        //console.log(response);
        this.bankaOdemeBilgileri = response;
        //console.log("backend tarafında banka 3d ve provizyon işlemleri");
        this.router.navigate(['odeme-ilerleme'], { state: { bankaOdemeBilgileri: this.bankaOdemeBilgileri } });
        //TODO: hata durumunda ne yapılacağını calısmak lazım.. burada baglantı hatası yiyebilir. Baska bir format hatası yiyebilir

      }, error => {
        this.spinner.hide();
        //debugger
        //console.log("odeme componenet -> odemeicin3DSorgusuYap -> error hata var");
        //console.log(error.error.message);
        this.mesaj.hata(error.error.message);
      });

    //subscribe oldugu sürece navigationda obje bos gıdıyor diger sınıfa
    // this.odemeService.odemeYap(this.odemeBilgileri).subscribe(
    //   (response) => {
    //     // Donuse göre işlem yap burada..
    //     this.spinner.hide();
    //     console.log("component.ts -> odemeService -> odemeYap() calisti");
    //     console.log(response);

    //     this.bankaOdemeBilgileri = response;
    //   }, () => {
    //     if (this.bankaOdemeBilgileri != null) {
    //       console.log('odeme servisinden geldi, odeme bekleniyor sayfasına yönleniyor.');
    //       this.router.navigate(['odeme-ilerleme']);
    //       debugger
    //     }
    //   }
    // )


  }

}