import { OdemeBilgileriDto } from "./odeme-bilgileri.model";

export class BankaOdemeBilgileriDto {

    acsUrl: string;
    secure3dsecuritylevel: string;
    mode: string;
    apiVersion: string;
    terminalProvUserID: string;
    terminalUserID: string;
    terminalMerchantID: string;
    txnType: string;
    txnAmount: string;
    currencyCode: string;
    installmentCount: string;
    customerEmailAddress: string;
    customerIpAddress: string;
    orderId: string;
    terminalID: string;
    successUrl: string;
    errorUrl: string;
    secureHashData: string;
    odemeBilgileriDto: OdemeBilgileriDto;

    public constructor() {
        this.odemeBilgileriDto = new OdemeBilgileriDto();
    }
}
