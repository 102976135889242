import { NullTemplateVisitor } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasvuruBilgileriDto } from '../../dto/basvuru-bilgileri.model';
import { CommonValidators, ValidRegex } from '../../common/CommonValidators';
import { PlatformLocation } from '@angular/common';
import { MessageComponent } from '../../message/message.component';

@Component({
  selector: 'app-fatura',
  templateUrl: './fatura.component.html',
  styleUrls: ['./fatura.component.css']
})
export class FaturaComponent implements OnInit {
  faturaDevam: boolean;
  faturaForm: FormGroup;
  basvuruSahibiSession: any;
  // sirketBasvurusuMu: boolean;
  buttonClicked: string
  sessiondanOku: boolean;
  iller: string[] = [];
  adimaKesilsin: boolean = false;


  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService,
    public commonValidators: CommonValidators, private basvuru: BasvuruBilgileriDto, location: PlatformLocation, private mesaj: MessageComponent) {


    //Eğer bir önceki sayfandan direk geliyorsa objeden al, yok F5 vs gibi reload ile geldiyse sessiondan al. Sessionda temiz ise başvuru saytfasına yönlen
    if (sessionStorage == null || sessionStorage.basvuruSahibi == null) {
      //console.log("boş veri olduğundan başvuru sayfasına yönlendiriliyor.");
      this.router.navigate(['basvuru']);
    }
    if (sessionStorage.basvuruSahibi != null) {
      this.basvuruSahibiSession = JSON.parse(sessionStorage.basvuruSahibi);
      if (this.basvuruSahibiSession != null) {
        if (this.basvuruSahibiSession.tckn == null) {
          this.router.navigate(['basvuru']);
        }
        else {
          if (this.basvuruSahibiSession.faturaBilgileri.faturaIl != null) {
            this.sessiondanOku = true;
          }
          else {
            this.sessiondanOku = false;
          }
        }
      }
    }
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.faturaDevam = false;
    // this.sirketBasvurusuMu = false;
    this.faturaForm = this.formBuilder.group({
      "adimaKesilsin": [null],
      "faturaAdresi": [null, [Validators.required]],
      "faturaIl": [null, [Validators.required]],
      "vergiDairesi": [null, [Validators.required]],
      "vergiNumarasi": [null, [Validators.required]],
      "sirketAdi": [null, [Validators.required]],
      "iletisimAdresi": [null, [Validators.required]],
      "iletisimIl": [null, [Validators.required]],
    })

    this.iller.push("ADANA");
    this.iller.push("ADIYAMAN");
    this.iller.push("AFYON");
    this.iller.push("AĞRI");
    this.iller.push("AKSARAY");
    this.iller.push("AMASYA");
    this.iller.push("ANKARA");
    this.iller.push("ANTALYA");
    this.iller.push("ARDAHAN");
    this.iller.push("ARTVİN");
    this.iller.push("AYDIN");
    this.iller.push("BALIKESİR");
    this.iller.push("BARTIN");
    this.iller.push("BATMAN");
    this.iller.push("BAYBURT");
    this.iller.push("BİLECİK");
    this.iller.push("BİNGÖL");
    this.iller.push("BİTLİS");
    this.iller.push("BOLU");
    this.iller.push("BURDUR");
    this.iller.push("BURSA");
    this.iller.push("ÇANAKKALE");
    this.iller.push("ÇANKIRI");
    this.iller.push("ÇORUM");
    this.iller.push("DENİZLİ");
    this.iller.push("DİYARBAKIR");
    this.iller.push("DÜZCE");
    this.iller.push("EDİRNE");
    this.iller.push("ELAZIĞ");
    this.iller.push("ERZİNCAN");
    this.iller.push("ERZURUM");
    this.iller.push("ESKİŞEHİR");
    this.iller.push("GAZİANTEP");
    this.iller.push("GİRESUN");
    this.iller.push("GÜMÜŞHANE");
    this.iller.push("HAKKARİ");
    this.iller.push("HATAY");
    this.iller.push("ISPARTA");
    this.iller.push("IĞDIR");
    this.iller.push("İSTANBUL");
    this.iller.push("İZMİR");
    this.iller.push("KAHRAMANMARAŞ");
    this.iller.push("KARABÜK");
    this.iller.push("KARAMAN");
    this.iller.push("KARS");
    this.iller.push("KASTAMONU");
    this.iller.push("KAYSERİ");
    this.iller.push("KIRIKKALE");
    this.iller.push("KIRKLARELİ");
    this.iller.push("KIRŞEHİR");
    this.iller.push("KİLİS");
    this.iller.push("KOCAELİ");
    this.iller.push("KONYA");
    this.iller.push("KÜTAHYA");
    this.iller.push("MALATYA");
    this.iller.push("MANİSA");
    this.iller.push("MARDİN");
    this.iller.push("MERSİN");
    this.iller.push("MUĞLA");
    this.iller.push("MUŞ");
    this.iller.push("NEVŞEHİR");
    this.iller.push("NİĞDE");
    this.iller.push("ORDU");
    this.iller.push("OSMANİYE");
    this.iller.push("RİZE");
    this.iller.push("SAKARYA");
    this.iller.push("SAMSUN");
    this.iller.push("SİİRT");
    this.iller.push("SİNOP");
    this.iller.push("SİVAS");
    this.iller.push("ŞANLIURFA");
    this.iller.push("ŞIRNAK");
    this.iller.push("TEKİRDAĞ");
    this.iller.push("TOKAT");
    this.iller.push("TRABZON");
    this.iller.push("TUNCELİ");
    this.iller.push("UŞAK");
    this.iller.push("VAN");
    this.iller.push("YALOVA");
    this.iller.push("YOZGAT")
    this.iller.push("ZONGULDAK");
    this.iller.push("DİĞER");
    //debugger
    if (this.sessiondanOku) {
      // this.faturaForm.controls.faturaAdresi = this.basvuruSahibiSession.faturaBilgileri.faturaAdresi;
      // this.faturaForm.controls.faturaIl = this.basvuruSahibiSession.faturaBilgileri.faturaIl;
      // this.faturaForm.controls.vergiDairesi = this.basvuruSahibiSession.faturaBilgileri.vergiDairesi;
      // this.faturaForm.controls.vergiNumarasi = this.basvuruSahibiSession.faturaBilgileri.vergiNumarasi;
      // this.faturaForm.controls.sirketAdi = this.basvuruSahibiSession.faturaBilgileri.sirketAdi;
    }

    // this.sirketBasvurusuMu = this.faturaForm.controls.sirketIse.value;
  }


  get alanlar() {
    return this.faturaForm.controls
  }

  onSubmit() {
    this.spinner.show();
    //console.log("basilan: " + this.buttonClicked);
    if (this.buttonClicked == "geri") {
      this.basvuruyaGeriGit();
    }
    else {
      this.onayaIlerle();
    }
  }

  basvuruyaGeriGit() {
    //console.log("ayiktir");
    this.router.navigate(['basvuru']);
  }


  adimaKesilsinDegistir(value: boolean) {
    this.spinner.show();
    this.adimaKesilsin = value;
    //debugger
    if (value) {
      this.faturaForm.controls["vergiDairesi"].setValidators(null);
      this.faturaForm.controls["vergiDairesi"].updateValueAndValidity();
      this.faturaForm.controls["vergiNumarasi"].setValidators(null);
      this.faturaForm.controls["vergiNumarasi"].updateValueAndValidity();
      this.faturaForm.controls["sirketAdi"].setValidators(null);
      this.faturaForm.controls["sirketAdi"].updateValueAndValidity();
   
    }
    else {
      this.faturaForm.controls["vergiDairesi"].setValidators([Validators.required]);
      this.faturaForm.controls["vergiDairesi"].updateValueAndValidity();
      this.faturaForm.controls["vergiNumarasi"].setValidators([Validators.required]);
      this.faturaForm.controls["vergiNumarasi"].updateValueAndValidity();
      this.faturaForm.controls["sirketAdi"].setValidators([Validators.required]);
      this.faturaForm.controls["sirketAdi"].updateValueAndValidity();

    }
    this.spinner.hide();
  }

  onayaIlerle() {

    this.spinner.show();
    // console.log("degeri = " + this.faturaForm.controls.sirketIse.value);
    //debugger
    this.faturaDevam = true;
    if (!this.faturaForm.valid) {
      this.spinner.hide();
      //this.mesaj.hata("Bilgileriniz eksik veya formatı hatalı, lütfen kontrol ediniz");
      return;
    }
    //debugger

    //adimaKesilsinDegistir fonksiyonunda adimaKesilsinMi yi set ediyorum... burada gerek yok..
    this.basvuru.faturaBilgileri.faturaAdresi = this.faturaForm.controls.faturaAdresi.value.trim();
    this.basvuru.faturaBilgileri.faturaIl = this.faturaForm.controls.faturaIl.value.trim();
    this.basvuru.faturaBilgileri.iletisimAdresi = this.faturaForm.controls.iletisimAdresi.value.trim();
    this.basvuru.faturaBilgileri.iletisimIl = this.faturaForm.controls.iletisimIl.value.trim();
    this.basvuru.faturaBilgileri.adimaKesilsinMi = this.adimaKesilsin;
    //eğer adıma kesilsin ise tckn'si vergi nuamrası olmak xorunda
    if (this.basvuru.faturaBilgileri.adimaKesilsinMi) {
      this.basvuru.faturaBilgileri.vergiNumarasi = this.basvuru.tckn;
    }
    else {
      if (this.faturaForm.controls.vergiDairesi.value != null) {
        this.basvuru.faturaBilgileri.vergiDairesi = this.faturaForm.controls.vergiDairesi.value.trim();
      }
      if (this.faturaForm.controls.vergiNumarasi.value != null) {
        this.basvuru.faturaBilgileri.vergiNumarasi = this.faturaForm.controls.vergiNumarasi.value.trim();
      }
      if (this.faturaForm.controls.sirketAdi.value != null) {
        this.basvuru.faturaBilgileri.sirketAdi = this.faturaForm.controls.sirketAdi.value.trim();
      }
    }



    // debugger
    var sessiondanBasvuru: BasvuruBilgileriDto = JSON.parse(sessionStorage.basvuruSahibi);
    sessionStorage.clear();


    sessionStorage.basvuruSahibi = JSON.stringify
      (
        {
          tckn: sessiondanBasvuru.tckn,
          // ad: this.basvuruBilgileri.ad,
          // soyad: this.basvuruBilgileri.soyad,
          dogumTarihi: sessiondanBasvuru.dogumTarihi,
          cepTelefonu: sessiondanBasvuru.cepTelefonu,
          eposta: sessiondanBasvuru.eposta,
          sertifikaSuresi: sessiondanBasvuru.sertifikaSuresi,
          kartOkuyucu: sessiondanBasvuru.kartOkuyucu,
          faturaBilgileri: this.basvuru.faturaBilgileri,
          // faturaBilgileri : JSON.stringify
          // (
          //   {
          //     faturaAdresi: this.basvuru.faturaBilgileri.faturaAdresi,
          //     faturaIl: this.basvuru.faturaBilgileri.faturaIl,
          //     //kurumsalBasvuru: this.basvuru.faturaBilgileri.kurumsalBasvuru,

          //     vergiDairesi: this.basvuru.faturaBilgileri.vergiDairesi,
          //     vergiNumarasi: this.basvuru.faturaBilgileri.vergiNumarasi,
          //     sirketAdi: this.basvuru.faturaBilgileri.sirketAdi,
          //   }
          // ),
          odenecekTutar: sessiondanBasvuru.odenecekTutar,
          basvuruKontrolBilgileri: sessiondanBasvuru.basvuruKontrolBilgileri,
        }
      );

    //debugger
    //diğer sayfaya objeyi gönder
    //console.log('odeme sayfasina yönleniyor');
    this.router.navigate(['onay']);
  }

}
