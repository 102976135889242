import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BasvuruComponent } from './pages/basvuru/basvuru.component';
import { OdemeComponent } from './pages/odeme/odeme.component';
import { CommonValidators } from "./common/CommonValidators";
import { NgxSoapModule } from 'ngx-soap';
import { BasvuruBilgileriDto } from './dto/basvuru-bilgileri.model';
import { OdemeBilgileriDto } from './dto/odeme-bilgileri.model';
import { OdemeIlerlemeComponent } from './pages/odeme-ilerleme/odeme-ilerleme.component';
import { BankaOdemeBilgileriDto } from './dto/banka-odeme.model';
import { OnlineOdemeSonucComponent } from './pages/online-odeme-sonuc/online-odeme-sonuc.component';
import { FaturaComponent } from './pages/fatura/fatura.component';
import { UiSwitchModule } from "ngx-ui-switch";
import { BasvuruKontrolDto } from './dto/basvuruKontrol.model';
import { FaturaBilgileriDto } from './dto/fatura.model';
import { MessageComponent } from './message/message.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { OnayComponent } from './pages/onay/onay.component';
import { DatePipe } from '@angular/common';



export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    NgxSoapModule,
    UiSwitchModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    BasvuruComponent,
    OnayComponent,
    OdemeComponent,
    OdemeIlerlemeComponent,
    OnlineOdemeSonucComponent,
    FaturaComponent,
    MessageComponent,
  ],
  providers: [FormBuilder, CommonValidators, BasvuruBilgileriDto, OdemeBilgileriDto, BankaOdemeBilgileriDto, BasvuruKontrolDto, FaturaBilgileriDto, DatePipe],
  //  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy},FormBuilder, CommonValidators,BasvuruBilgileriDto,OdemeBilgileriDto, BankaOdemeBilgileriDto],
  bootstrap: [AppComponent]
})
export class AppModule { }
