import { Component, Injectable, OnInit } from '@angular/core';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.css']
})
@Injectable({
  providedIn: 'root'
})
export class MessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  basarili(textMsg: string): void {
    Swal.fire({
      title: 'Başarılı',
      text: textMsg,
      icon: 'success',
      confirmButtonText: 'Tamam'
    });
  }

  hata(textMsg: string): void {
    Swal.fire({
      title: 'Hata!',
      text: textMsg,
      icon: 'error',
      confirmButtonText: 'Tamam'
    })
  }

  uyari(textMsg: string): void {
    Swal.fire({
      title: 'Uyarı!',
      text: textMsg,
      icon: 'warning',
      confirmButtonText: 'Tamam'
    })
  }

  
  bilgi(textMsg: string): void {
    Swal.fire({
      title: 'Bilgi!',
      text: textMsg,
      icon: 'info',
      confirmButtonText: 'Tamam'
    })
  }

  noConfirm(textMsg: string): void {
    Swal.fire({
      title: 'Başarılı',
      text: textMsg,
      icon: 'success',
      showConfirmButton: false,
      showClass: {
        backdrop: 'swal2-noanimation', // disable backdrop animation
        popup: '',                     // disable popup animation
      },
      hideClass: {
        popup: '',                     // disable popup fade-out animation
      },
    });
  }

  confirmButton = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger'
    },
    buttonsStyling: false
  })

}
