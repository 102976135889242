import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Client, ISoapMethodResponse, NgxSoapService } from 'ngx-soap';
import { NgxSpinnerService } from 'ngx-spinner';
import { BasvuruBilgileriDto } from '../../dto/basvuru-bilgileri.model';
import { CommonValidators, ValidRegex } from '../../common/CommonValidators';
import { PlatanService } from '../../services/platan.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MessageComponent } from '../../message/message.component';
import { DatePipe, PlatformLocation } from '@angular/common';



@Component({
  selector: 'app-basvuru',
  templateUrl: './basvuru.component.html',
  styleUrls: ['./basvuru.component.css']
})
export class BasvuruComponent implements OnInit {

  basvuruForm: FormGroup;
  client: Client;
  basvuruDevam = false;
  //struct: NgbDateStruct;
  //--
  loading: boolean;
  message: string;
  xmlResponse: string;
  sertifikaSureleri = [
    { yilAdi: '1 Yıllık', yilSuresi: '1', ucreti: '509' },
    { yilAdi: '2 Yıllık', yilSuresi: '2', ucreti: '699' },
    { yilAdi: '3 Yıllık', yilSuresi: '3', ucreti: '999' },
  ];
  seciliSertifikaYili: any;
  kartOkuyucuUcreti: string = "600";
  sertifikaUcreti: string = "0";
  toplamUcret: number = 0;
  kartOkuyucu = false;
  bildirimMesaji: string;
  maxTarih: any;

  constructor(private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, public commonValidators: CommonValidators,
    /*private soap: NgxSoapService,*/ private http: HttpClient, private basvuruBilgileri: BasvuruBilgileriDto, private platanService: PlatanService,
    private mesaj: MessageComponent,public datepipe: DatePipe) {

    this.bildirimMesaji = 'Başvuru işlemleri sonrasında, Nüfus Müdürlüklerinde bulunan Nüfusmatiklerde, e-imzanızı T.C. Kimlik kartınıza yükleyebilirsiniz.';
    //---------------------------------------
    // SOAP SERVISI ICIN CLIENT CREATE ETME
    //---------------------------------------
    // this.soap.createClient('assets/soapservice/turktrust.wsdl')
    //   .then(client => {
    //     console.log('Client', client);
    //     this.client = client;
    //   })
    //   .catch(err => console.log('Error', err));

    //var extraHeaders = { Username: "deneme", Password: "123456" };
    // let headerim = new HttpHeaders({
    //   'Username':'deneme',
    //   'Password':'123456'
    // })

    // this.soap.createClient('assets/soapservice/turktrust.wsdl',{headers:headerim})
    //   .then(client => {
    //     console.log('Client', client);
    //     this.client = client;
    //   })
    //   .catch(err => console.log('Error', err));


    //   this.soap.createClient('assets/soapservice/turktrust.wsdl')
    //     .then(client => {
    //       client.addSoapHeader({
    //           'Username':'deneme',
    //           'Password':'123456'
    //       })
    //       console.log('Client', client);
    //       this.client = client;
    //     })
    //     .catch(err => console.log('Error', err));
    this.spinner.hide();
  }


  ngOnInit(): void {
    this.basvuruDevam = false;
    this.basvuruForm = this.formBuilder.group({
      "tckn": [null, [Validators.required, Validators.minLength(11), Validators.pattern("^[0-9]*$")]],
      // "ad": [null, [Validators.required, Validators.pattern(ValidRegex.harfRegex)]],
      // "soyad": [null, [Validators.required, Validators.pattern(ValidRegex.harfRegex)]],
      "dogumTarihi": [null, [Validators.required,CommonValidators.yasKontrolValidator(18)]],
      "cepTelefonu": [null, [Validators.required, Validators.minLength(14)]],
      "eposta": [null, [Validators.required, Validators.pattern(ValidRegex.emailRegex), Validators.maxLength(99)]],
      "sertifikaSuresi": [null, [Validators.required]],
    })

    this.maxTarih = new Date().toISOString().slice(0, 10);
   
    //this.mesaj.uyari("Şu anda sistemde bakım çalışması yapılmaktadır, başvuru işleminizi web sitemiz üzerinden takip ederek yapmanızı rica ederiz.");
    // this.mesaj.uyari("Şu anda projede gerçekleştirilen yaygınlaştırma çalışması nedeniyle sistem güncellemesi yapılmaktadır, başvuru işleminizi web sitemiz üzerinden takip ederek yapmanızı rica ederiz.");
    //return;

  }

  get alanlar() {
    return this.basvuruForm.controls
  }

  sertifikaSuresiniDegistir(e) {
    this.spinner.show();
    //console.log(this.basvuruForm.controls.cepTelefonu.value)
    this.basvuruBilgileri.sertifikaSuresi = this.basvuruForm.controls.sertifikaSuresi.value;
    this.seciliSertifikaYili = this.sertifikaSureleri.filter(value => value.yilSuresi == e.target.value);
    //console.log("secili olan yıl: " + e.target.value);
    //console.log("ucret: " + this.seciliSertifikaYili[0].ucreti);
    this.sertifikaUcreti = this.seciliSertifikaYili[0].ucreti;
    if (this.kartOkuyucu) {
      this.toplamUcret = parseInt(this.sertifikaUcreti) + parseInt(this.kartOkuyucuUcreti);
    }
    else {
      this.toplamUcret = parseInt(this.sertifikaUcreti);
    }
    this.spinner.hide();
  }
  okuyucuUcretiEkleCikar(value: boolean) {
    this.spinner.show();
    this.kartOkuyucu = value;
    //console.log("okuyucu durumu: " + this.kartOkuyucu);
    if (this.kartOkuyucu) {
      this.toplamUcret = parseInt(this.sertifikaUcreti) + parseInt(this.kartOkuyucuUcreti);
    }
    else {
      this.toplamUcret = parseInt(this.sertifikaUcreti);
    }
    this.spinner.hide();
  }

  faturayaIlerle() {

    // this.mesaj.uyari("Şu anda projede gerçekleştirilen yaygınlaştırma çalışması nedeniyle sistem güncellemesi yapılmaktadır, başvuru işleminizi web sitemiz üzerinden takip ederek yapmanızı rica ederiz.");
    // return;
    //console.log("dogum tarihi: " + this.datepipe.transform(this.basvuruForm.controls.dogumTarihi.value, 'dd/MM/yyyy'));

    this.spinner.show();
    this.basvuruDevam = true;

    if (!this.basvuruForm.valid) {
      this.spinner.hide();
      //this.mesaj.hata("Bilgileriniz eksik veya formatı hatalı, lütfen kontrol ediniz");
      return;
    }

    if (!this.tcknAlgoritmaKontrol(this.basvuruForm.controls.tckn.value)) {
      this.spinner.hide();
      this.mesaj.hata("TCKN formatınız hatalı. Lütfen kontrol ediniz.");
      return;
    }


    //soap servisi deneme fonksiyonu
    //this.stringeCevir();


    /* Farklı gönderme yöntemi fakat url de gözüküyor.
      this.router.navigate([`${'odeme'}`],{queryParams:{
        //Farklı yöntem
        //tckn:this.basvuruForm.get('tckn').value,
        tckn:this.basvuruForm.controls.tckn.value,
        adSoyad:this.basvuruForm.controls.ad.value + ' ' + this.basvuruForm.controls.soyad.value
      }});
  */

    //modeli hazirla

    this.basvuruBilgileri.tckn = this.basvuruForm.controls.tckn.value;
    // this.basvuruBilgileri.ad = this.basvuruForm.controls.ad.value;
    // this.basvuruBilgileri.soyad = this.basvuruForm.controls.soyad.value;
    this.basvuruBilgileri.dogumTarihi =this.datepipe.transform(this.basvuruForm.controls.dogumTarihi.value, 'dd/MM/yyyy');
    this.basvuruBilgileri.cepTelefonu = this.basvuruForm.controls.cepTelefonu.value;
    this.basvuruBilgileri.eposta = this.basvuruForm.controls.eposta.value.trim();
    this.basvuruBilgileri.sertifikaSuresi = this.basvuruForm.controls.sertifikaSuresi.value;
    this.basvuruBilgileri.kartOkuyucu = this.kartOkuyucu;
    this.basvuruBilgileri.odenecekTutar = this.toplamUcret;

    this.platanService.basvuruIcinTCKontroluYap(this.basvuruBilgileri).subscribe(
      (response) => {
        // debugger
        //console.log("component.ts -> platanService -> basvuruIcinTCKontroluYap() calisti");
        //console.log("response: " + response);

        this.basvuruBilgileri.basvuruKontrolBilgileri = response;
        //console.log("basvuru numarasi: " + this.basvuruBilgileri.basvuruKontrolBilgileri.basvuruNumarasi);
        //console.log("takip numarasi: " + this.basvuruBilgileri.basvuruKontrolBilgileri.takipNumarasi);
        this.islemiYonlendir();

      }, error => {
        this.spinner.hide();
        // debugger
        // console.log("basvuru componenet -> basvuruIcinTCKontroluYap -> error hata var");
        //console.log(error.error.message);
        this.mesaj.hata(error.error.message);
      }
    );
  }


  islemiYonlendir() {
    sessionStorage.basvuruSahibi = JSON.stringify
      (
        {
          tckn: this.basvuruBilgileri.tckn,
          // ad: this.basvuruBilgileri.ad,
          // soyad: this.basvuruBilgileri.soyad,
          dogumTarihi: this.basvuruBilgileri.dogumTarihi,
          cepTelefonu: this.basvuruBilgileri.cepTelefonu,
          eposta: this.basvuruBilgileri.eposta,
          sertifikaSuresi: this.basvuruBilgileri.sertifikaSuresi,
          kartOkuyucu: this.basvuruBilgileri.kartOkuyucu,
          faturaBilgileri: this.basvuruBilgileri.faturaBilgileri,
          odenecekTutar: this.basvuruBilgileri.odenecekTutar,
          basvuruKontrolBilgileri: this.basvuruBilgileri.basvuruKontrolBilgileri,
        }
      );


    //diğer sayfaya objeyi gönder
    //console.log('fatura sayfasina yönleniyor');

    sessionStorage.setItem("basvuru", "OK");
    this.router.navigate(['fatura']);
  }

  tcknAlgoritmaKontrol(tckn: String): any {
    let toplam: number = 0;
    if (tckn[0] == "0") {
      return false;
    }

    for (let index = 0; index < 10; index++) {
      toplam = toplam + Number(tckn[index]);
    }
    //console.log("toplam " + toplam);

    if (toplam % 10 == Number(tckn[10])) {
      //console.log((Number(tckn[0]) + Number(tckn[2]) + Number(tckn[4]) + Number(tckn[6]) + Number(tckn[8])) * 7);
      //console.log((Number(tckn[1]) + Number(tckn[3]) + Number(tckn[5]) + Number(tckn[7])));

      let ilk: number = (Number(tckn[0]) + Number(tckn[2]) + Number(tckn[4]) + Number(tckn[6]) + Number(tckn[8])) * 7;
      let ikinci: number = Number(tckn[1]) + Number(tckn[3]) + Number(tckn[5]) + Number(tckn[7]);
      if ((ilk - ikinci) % 10 == Number(tckn[9])) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }

  }

  //-----------------------------------------------
  // DAHA SONRA SOAP XML SERVISINI CAGIRMA ISLEMI DENENEBİLİR
  //-----------------------------------------------
  // stringeCevir() {
  //   this.loading = true;
  //   const body = {
  //     ubiNum: 5
  //   };
  //   this.client.call('NumberToWords', body).subscribe(res => {
  //     console.log("gidiyor");
  //     this.xmlResponse = res.responseBody;
  //     this.message = res.result.NumberToWordsResult;
  //     this.loading = false;
  //     console.log(this.xmlResponse);
  //     console.log(this.message);
  //   }, err => console.log(err));
  // }

  // stringeCevir2() {
  //   this.loading = true;
  //   const body = {
  //     ubiNum: 10
  //   };
  //   (<any>this.client).NumberToWords(body).subscribe(
  //     (res: ISoapMethodResponse) => {
  //       console.log('method response', res);
  //       this.xmlResponse = res.xml;
  //       this.message = res.result.NumberToWordsResult;
  //       this.loading = false;
  //     },
  //     err => console.log(err)
  //   );
  // }

  // veriyiGonderKontrolEt1() {
  //   this.loading = true;
  //   const body = {
  //     tckn: this.basvuruForm.controls.tckn.value
  //   };
  //   this.client.call('OnlineTCKimlikBasvuruServisi.tcKimlikKartiOnlineImzaBasvuruDurumSorgulama', body).subscribe(res => {
  //     console.log("gidiyor");
  //     this.xmlResponse = res.responseBody;
  //     this.message = res.result.NumberToWordsResult;
  //     this.loading = false;
  //     console.log(this.xmlResponse);
  //     console.log(this.message);
  //   }, err => console.log(err));
  // }

  // veriyiGonderKontrolEt2() {
  //   this.loading = true;
  //   const body = {
  //     tckn: this.basvuruForm.controls.tckn.value
  //   };
  //   (<any>this.client).tcKimlikKartiOnlineImzaBasvuruDurumSorgulama(body).subscribe(
  //     (res: ISoapMethodResponse) => {
  //       console.log('method response', res);
  //       this.xmlResponse = res.xml;
  //       this.message = res.result.tcKimlikKartiOnlineImzaBasvuruDurumSorgulamaResponse;
  //       this.loading = false;
  //     },
  //     err => console.log(err)
  //   );
  // }

}
