import { BasvuruKontrolDto } from "./basvuruKontrol.model";
import { FaturaBilgileriDto } from "./fatura.model";

export class BasvuruBilgileriDto {
    tckn: string;
    // ad: string;
    // soyad: string;
    dogumTarihi: string;
    cepTelefonu: string;
    eposta: string;
    sertifikaSuresi: string;
    kartOkuyucu: boolean;
    faturaBilgileri: FaturaBilgileriDto;
    basvuruKontrolBilgileri: BasvuruKontrolDto;
    odenecekTutar:number;

    public constructor() {
        this.basvuruKontrolBilgileri = new BasvuruKontrolDto();
        this.faturaBilgileri = new FaturaBilgileriDto();
    }
}
