
export class FaturaBilgileriDto {
    faturaAdresi: string;
    faturaIl: string;
    kurumsalBasvuru: boolean;
    vergiDairesi: string;
    vergiNumarasi: string;
    sirketAdi: string;
    adimaKesilsinMi: boolean;
    iletisimAdresi: string;
    iletisimIl: string;


    public constructor() { }
}

