import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BankaOdemeBilgileriDto } from '../../dto/banka-odeme.model';
import { OdemeService } from '../../services/odeme.service';

@Component({
  selector: 'app-odeme-ilerleme',
  templateUrl: './odeme-ilerleme.component.html',
  styleUrls: ['./odeme-ilerleme.component.css']
})
export class OdemeIlerlemeComponent implements OnInit {
  odemeBekleme: FormGroup;
  odemeDevam: boolean;
  bankaLink: any;
  //@ViewChild('testForm') testFormElement;
  @ViewChild('myForm') myForm: ElementRef;

  // private httpOptions = {
  //   headers: new HttpHeaders(
  //     {
  //       'Accept': 'text/html, application/xhtml+xml, */*',
  //       'Content-Type': 'application/x-www-form-urlencoded'
  //     }),
  //   responseType: 'text'
  // }

  constructor(private activeRouter: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private odemeService: OdemeService, private bankaOdemeBilgileri: BankaOdemeBilgileriDto, private http: HttpClient) {
    //console.log(JSON.stringify(this.bankaOdemeBilgileri)); 
   // console.log('odeme ekranindan gelen servis eklenmis bilgiler:')
   // console.log(this.router.getCurrentNavigation().extras.state.bankaOdemeBilgileri);
    this.bankaOdemeBilgileri = this.router.getCurrentNavigation().extras.state.bankaOdemeBilgileri;
  }

  ngOnInit(): void {
    this.bankaLink = this.bankaOdemeBilgileri.acsUrl;
    //console.log('banka linki : ', this.bankaLink)

    this.odemeBekleme = this.formBuilder.group({
      //odemeBilgileriDTOdan gelen bir kac veriyi de buraya hidden field olarak yazmak gerekecek
      "cardnumber": [{
        value: this.bankaOdemeBilgileri.odemeBilgileriDto.krediKartiNumarasi,
        disabled: false
      }],
      "cardcvv2": [{
        value: this.bankaOdemeBilgileri.odemeBilgileriDto.cvv,
        disabled: false
      }],
      "cardexpiredatemonth": [{
        value: this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiAy,
        disabled: false
      }],
      "cardexpiredateyear": [{
        value: this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiYil,
        disabled: false
      }],
      "secure3dsecuritylevel": [{
        value: this.bankaOdemeBilgileri.secure3dsecuritylevel,
        disabled: false
      }],
      "mode": [{
        value: this.bankaOdemeBilgileri.mode,
        disabled: false
      }],
      "apiVersion": [{
        value: this.bankaOdemeBilgileri.apiVersion,
        disabled: false
      }],
      "terminalProvUserID": [{
        value: this.bankaOdemeBilgileri.terminalProvUserID,
        disabled: false
      }],
      "terminalUserID": [{
        value: this.bankaOdemeBilgileri.terminalUserID,
        disabled: false
      }],
      "terminalMerchantID": [{
        value: this.bankaOdemeBilgileri.terminalMerchantID,
        disabled: false
      }],
      "txntype": [{
        value: this.bankaOdemeBilgileri.txnType,
        disabled: false
      }],
      "txnamount": [{
        value: this.bankaOdemeBilgileri.txnAmount,
        disabled: false
      }],
      "txncurrencycode": [{
        value: this.bankaOdemeBilgileri.currencyCode,
        disabled: false
      }],
      "txninstallmentcount": [{
        value: this.bankaOdemeBilgileri.installmentCount,
        disabled: false
      }],
      "orderid": [{
        value: this.bankaOdemeBilgileri.orderId,
        disabled: false
      }],
      "terminalid": [{
        value: this.bankaOdemeBilgileri.terminalID,
        disabled: false
      }],
      "successurl": [{
        value: this.bankaOdemeBilgileri.successUrl,
        disabled: false
      }],
      "errorurl": [{
        value: this.bankaOdemeBilgileri.errorUrl,
        disabled: false
      }],
      "customeremailaddress": [{
        value: this.bankaOdemeBilgileri.customerEmailAddress,
        disabled: false
      }],
      "customeripaddress": [{
        value: this.bankaOdemeBilgileri.customerIpAddress,
        disabled: false
      }],
      "secure3dhash": [{
        value: this.bankaOdemeBilgileri.secureHashData,
        disabled: false
      }]
    });
    //this.testFormElement.nativeElement.submit();

  }

  ngAfterViewInit() {
    // form postinde aşağısı
    this.myForm.nativeElement.submit();

    // formdata postingde  aşağısı
    // this.odemeyiBankadaKontrolEt();
  //  console.log("form gidiyor..");

  }

  //kullanılmayacak önede form submitting yapılacak
  odemeyiBankadaKontrolEt() {
   // console.log('haydaaaaa');

    // const data = [
    //   { cardnumber: this.bankaOdemeBilgileri.odemeBilgileriDto.krediKartiNumarasi },
    //   { cardcvv2: this.bankaOdemeBilgileri.odemeBilgileriDto.cvv },
    //   { cardexpiredatemonth: this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiAy },
    //   { cardexpiredateyear: this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiYil },
    //   { lang: this.bankaOdemeBilgileri.lang },
    //   { secure3dsecuritylevel: this.bankaOdemeBilgileri.secure3dsecuritylevel },
    //   { mode: this.bankaOdemeBilgileri.mode },
    //   { apiVersion: this.bankaOdemeBilgileri.apiVersion },
    //   { terminalProvUserID: this.bankaOdemeBilgileri.terminalProvUserID },
    //   { terminalUserID: this.bankaOdemeBilgileri.terminalUserID },
    //   { terminalMerchantID: this.bankaOdemeBilgileri.terminalMerchantID },
    //   { txntype: this.bankaOdemeBilgileri.txnType },
    //   { txnamount: this.bankaOdemeBilgileri.txnAmount },
    //   { txncurrencycode: this.bankaOdemeBilgileri.strCurrencyCode },
    //   { txninstallmentcount: this.bankaOdemeBilgileri.installmentCount },
    //   { orderid: this.bankaOdemeBilgileri.orderId },
    //   { terminalid: this.bankaOdemeBilgileri.terminalID },
    //   { successurl: this.bankaOdemeBilgileri.successUrl },
    //   { errorurl: this.bankaOdemeBilgileri.errorUrl },
    //   { customeremailaddress: this.bankaOdemeBilgileri.customerEmailAddress },
    //   { customeripaddress: this.bankaOdemeBilgileri.customerIpAddress },
    //   { secure3dhash: this.bankaOdemeBilgileri.secureHashData },
    //   { refreshtime: this.bankaOdemeBilgileri.refreshtime },
    // ];

    // this.http.post(this.bankaOdemeBilgileri.acsUrl, formData).subscribe((sonuc) => {
    //   console.warn('sonuc:', sonuc);
    // })

    //let headers = new HttpHeaders().set('Content-Type', 'multipart/form-data');
    // let options = {
    //   content:formData,
    //   headers: headers,
    //   observe: 'body', 
    //   responseType: 'text' 
    // };

    // const headerDict = {
    //   'Content-Type': 'text/plain;charset=UTF-8',
    //   'Accept': 'application/xml',
    //   'Access-Control-Allow-Headers': 'Content-Type',
    // }

    // const requestOptions = {
    //   headers: new HttpHeaders(headerDict),
    // };

    // this.http.post('https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine', formData,
    //   { headers: new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' }) })
    //   .subscribe(() => console.log("request done with success"));

    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'text/html');


    //   this.http.post("https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine", {
    //     'cardnumber': this.bankaOdemeBilgileri.odemeBilgileriDto.krediKartiNumarasi,
    //     'cardcvv2': this.bankaOdemeBilgileri.odemeBilgileriDto.cvv,
    //     'cardexpiredatemonth': this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiAy,
    //     'cardexpiredateyear': this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiYil,
    //     'lang': this.bankaOdemeBilgileri.lang,
    //     'secure3dsecuritylevel': this.bankaOdemeBilgileri.secure3dsecuritylevel,
    //     'mode': this.bankaOdemeBilgileri.mode,
    //     'apiVersion': this.bankaOdemeBilgileri.apiVersion,
    //     'terminalProvUserID': this.bankaOdemeBilgileri.terminalProvUserID,
    //     'terminalUserID': this.bankaOdemeBilgileri.terminalUserID,
    //     'terminalMerchantID': this.bankaOdemeBilgileri.terminalMerchantID,
    //     'txntype': this.bankaOdemeBilgileri.txnType,
    //     'txnamount': this.bankaOdemeBilgileri.txnAmount,
    //     'txncurrencycode': this.bankaOdemeBilgileri.strCurrencyCode,
    //     'txninstallmentcount': this.bankaOdemeBilgileri.installmentCount,
    //     'orderid': this.bankaOdemeBilgileri.orderId,
    //     'terminalid': this.bankaOdemeBilgileri.terminalID,
    //     'successurl': this.bankaOdemeBilgileri.successUrl,
    //     'errorurl': this.bankaOdemeBilgileri.errorUrl,
    //     'customeremailaddress': this.bankaOdemeBilgileri.customerEmailAddress,
    //     'customeripaddress': this.bankaOdemeBilgileri.customerIpAddress,
    //     'secure3dhash': this.bankaOdemeBilgileri.secureHashData,
    //     'refreshtime': this.bankaOdemeBilgileri.refreshtime
    // }, { observe: 'body', responseType: 'text' }).subscribe(
    //     (response) => console.log('if succes: ', response),
    //     (error) => console.log('if error: ', error)
    //   )


    // formData.append("data", JSON.stringify(data));
    // this.http.post("https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine", JSON.stringify(data), { observe: 'body', responseType: 'text' }).subscribe(
    //   (response) => console.log('if succes: ', response),
    //   (error) => console.log('if error: ', error)
    // )


    // let xmlBody = "<request>"
    // "<secure3dsecuritylevel>" + this.bankaOdemeBilgileri.secure3dsecuritylevel + "</secure3dsecuritylevel>"
    // "<mode>" + this.bankaOdemeBilgileri.mode + "</mode>"
    // "<apiVersion>" + this.bankaOdemeBilgileri.apiVersion + "</apiVersion>"
    // "<terminalProvUserID>" + this.bankaOdemeBilgileri.terminalProvUserID + "</terminalProvUserID>"
    // "<terminalUserID>" + this.bankaOdemeBilgileri.terminalUserID + "</terminalUserID>"
    // "<terminalMerchantID>" + this.bankaOdemeBilgileri.terminalMerchantID + "</terminalMerchantID>"
    // "<txntype>" + this.bankaOdemeBilgileri.txnType + "</txntype>"
    // "<txnamount>" + this.bankaOdemeBilgileri.txnAmount + "</txnamount>"
    // "<txncurrencycode>" + this.bankaOdemeBilgileri.currencyCode + "</txncurrencycode>"
    // "<txninstallmentcount>" + this.bankaOdemeBilgileri.installmentCount + "</txninstallmentcount>"
    // "<customeremailaddress>" + this.bankaOdemeBilgileri.customerEmailAddress + "</customeremailaddress>"
    // "<customeripaddress>" + this.bankaOdemeBilgileri.customerIpAddress + "</customeripaddress>"
    // "<orderid>" + this.bankaOdemeBilgileri.orderId + "</orderid>"
    // "<terminalid>" + this.bankaOdemeBilgileri.terminalID + "</terminalid>"
    // "<successurl>" + this.bankaOdemeBilgileri.successUrl + "</successurl>"
    // "<errorurl>" + this.bankaOdemeBilgileri.errorUrl + "</errorurl>"
    // "<secure3dhash>" + this.bankaOdemeBilgileri.secureHashData + "</secure3dhash>"
    // "<cardnumber>" + this.bankaOdemeBilgileri.odemeBilgileriDto.krediKartiNumarasi + "</cardnumber>"
    // "<cardexpiredatemonth>" + this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiAy + "</cardexpiredatemonth>"
    // "<cardexpiredateyear>" + this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiYil + "</cardexpiredateyear>"
    // "<cardcvv2>" + this.bankaOdemeBilgileri.odemeBilgileriDto.cvv + "</cardcvv2>"
    // "</request>";

    // var formData: any = new FormData();
    // var formData = new FormData();
    // formData.append('secure3dsecuritylevel', this.bankaOdemeBilgileri.secure3dsecuritylevel);
    // formData.append('mode', this.bankaOdemeBilgileri.mode);
    // formData.append('apiversion', this.bankaOdemeBilgileri.apiVersion);
    // formData.append('terminalprovuserid', this.bankaOdemeBilgileri.terminalProvUserID);
    // formData.append('terminaluserid', this.bankaOdemeBilgileri.terminalUserID);
    // formData.append('terminalmerchantid', this.bankaOdemeBilgileri.terminalMerchantID);
    // formData.append('txntype', this.bankaOdemeBilgileri.txnType);
    // formData.append('txnamount', this.bankaOdemeBilgileri.txnAmount);
    // formData.append('txncurrencycode', this.bankaOdemeBilgileri.currencyCode);
    // formData.append('txninstallmentcount', this.bankaOdemeBilgileri.installmentCount);
    // formData.append('customeremailaddress', this.bankaOdemeBilgileri.customerEmailAddress);
    // formData.append('customeripaddress', this.bankaOdemeBilgileri.customerIpAddress);
    // formData.append('orderid', this.bankaOdemeBilgileri.orderId);
    // formData.append('terminalid', this.bankaOdemeBilgileri.terminalID);
    // formData.append('successurl', this.bankaOdemeBilgileri.successUrl);
    // formData.append('errorurl', this.bankaOdemeBilgileri.errorUrl);
    // formData.append('secure3dhash', this.bankaOdemeBilgileri.secureHashData);
    // formData.append('cardnumber', this.bankaOdemeBilgileri.odemeBilgileriDto.krediKartiNumarasi);
    // formData.append('cardexpiredatemonth', this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiAy);
    // formData.append('cardexpiredateyear', this.bankaOdemeBilgileri.odemeBilgileriDto.sonKullanmaTarihiYil);
    // formData.append('cardcvv2', this.bankaOdemeBilgileri.odemeBilgileriDto.cvv);


    // this.http.post("https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine", formData, {headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, observe: 'body', responseType: 'text' }).subscribe(
    //   (response) => console.log('if succes: ', response),
    //   (error) => console.log('if error: ', error)
    // )

    // console.log('link :', formData.get("successurl"));
    // this.http.post("https://sanalposprovtest.garanti.com.tr/servlet/gt3dengine/", formData, { observe: 'body', responseType: 'text' }).subscribe(
    //   (response) => console.log('if succes: ', response),
    //   (error) => console.log('if error: ', error)
    // )

  }
}
