import { PlatformLocation } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Event as NavigationEvent } from "@angular/router";
import { filter } from "rxjs/operators";
import { BasvuruBilgileriDto } from '../../dto/basvuru-bilgileri.model';
import { MessageComponent } from '../../message/message.component';

@Component({
  selector: 'app-onay',
  templateUrl: './onay.component.html',
  styleUrls: ['./onay.component.css']
})
export class OnayComponent implements OnInit {
  onayForm: FormGroup;
  tckn: string;
  dogumTarihi: string;
  eposta: string;
  cepTel: string;
  sertifikaSuresi: string;
  kartOkuyucu: string;
  ucret: number;
  faturaAdresi: string;
  faturaIl: string;
  iletisimAdresi: string;
  iletisimIl: string;
  adimaKesilsin: string;
  adimaKesilsinMi:boolean;
  vergiDairesi: string;
  vergiNumarasi: string;
  sirketAdi: string;
  buttonClicked: string


  constructor(private router: Router,
    private spinner: NgxSpinnerService,
    private basvuru: BasvuruBilgileriDto,
    private formBuilder: FormBuilder, private mesaj: MessageComponent) {


    //-------------
    // router.events
    // .pipe(
    // 	// The "events" stream contains all the navigation events. For this demo,
    // 	// though, we only care about the NavigationStart event as it contains
    // 	// information about what initiated the navigation sequence.
    // 	filter(
    // 		( event: NavigationEvent ) => {

    // 			return( event instanceof NavigationStart );

    // 		}
    // 	)
    // )
    // .subscribe
    // (
    // 	( event: NavigationStart ) => {

    // 		console.group( "NavigationStart Event" );
    // 		// Every navigation sequence is given a unique ID. Even "popstate"
    // 		// navigations are really just "roll forward" navigations that get
    // 		// a new, unique ID.
    // 		console.log( "navigation id:", event.id );
    // 		console.log( "route:", event.url );
    // 		// The "navigationTrigger" will be one of:
    // 		// --
    // 		// - imperative (ie, user clicked a link).
    // 		// - popstate (ie, browser controlled change such as Back button).
    // 		// - hashchange
    // 		// --
    // 		// NOTE: I am not sure what triggers the "hashchange" type.
    // 		console.log( "trigger:", event.navigationTrigger );

    // 		// This "restoredState" property is defined when the navigation
    // 		// event is triggered by a "popstate" event (ex, back / forward
    // 		// buttons). It will contain the ID of the earlier navigation event
    // 		// to which the browser is returning.
    // 		// --
    // 		// CAUTION: This ID may not be part of the current page rendering.
    // 		// This value is pulled out of the browser; and, may exist across
    // 		// page refreshes.
    // 		if ( event.restoredState ) {

    // 			console.warn(
    // 				"restoring navigation id:",
    // 				event.restoredState.navigationId
    // 			);

    // 		}

    // 		console.groupEnd();

    // 	}
    // );
    //-------------



    if (sessionStorage == null || sessionStorage.basvuruSahibi == null) {
      //console.log("boş veri olduğundan başvuru sayfasına yönlendiriliyor.");
      this.router.navigate(['basvuru']);
    }
    //JSON parse kısmında hata olursa..
    if (sessionStorage.basvuruSahibi != null) {
      this.basvuru = JSON.parse(sessionStorage.basvuruSahibi);
      if (this.basvuru != null) {
        if (this.basvuru.tckn == null) {
          this.router.navigate(['basvuru']);
        }
      }
    }
    this.spinner.hide();
  }

  ngOnInit(): void {
    this.tckn = this.basvuru.tckn;
    this.dogumTarihi = this.basvuru.dogumTarihi;
    this.eposta = this.basvuru.eposta;
    this.cepTel = this.basvuru.cepTelefonu;
    this.sertifikaSuresi = this.basvuru.sertifikaSuresi;
    if (this.basvuru.kartOkuyucu) {
      this.kartOkuyucu = "EVET"
    }
    else {
      this.kartOkuyucu = "HAYIR"
    }
    this.ucret = this.basvuru.odenecekTutar;
    this.faturaAdresi = this.basvuru.faturaBilgileri.faturaAdresi;
    this.faturaIl = this.basvuru.faturaBilgileri.faturaIl;
    this.iletisimAdresi = this.basvuru.faturaBilgileri.iletisimAdresi;
    this.iletisimIl = this.basvuru.faturaBilgileri.iletisimIl;
    //boolean
    this.adimaKesilsinMi=this.basvuru.faturaBilgileri.adimaKesilsinMi;
    //string
    if (this.basvuru.faturaBilgileri.adimaKesilsinMi) {
      this.adimaKesilsin = "EVET"
    }
    else {
      this.adimaKesilsin = "HAYIR"
    }
    if (!this.basvuru.faturaBilgileri.adimaKesilsinMi) {
      this.vergiDairesi = this.basvuru.faturaBilgileri.vergiDairesi;
      this.vergiNumarasi = this.basvuru.faturaBilgileri.vergiNumarasi;
      this.sirketAdi = this.basvuru.faturaBilgileri.sirketAdi;
    }

    this.onayForm = this.formBuilder.group({
    });
  }

  onSubmit() {
    this.spinner.show();
    //console.log("basilan: " + this.buttonClicked);
    if (this.buttonClicked == "geri") {
      this.faturayaGeriGit();
    }
    else {
      this.odemeyeIlerle();
    }
  }

  odemeyeIlerle() {
    //diğer sayfaya objeyi gönder
    //console.log('odeme sayfasina yönleniyor');
    this.router.navigate(['odeme']);

  }

  faturayaGeriGit() {
    //console.log("ayiktir");
    this.router.navigate(['fatura']);
  }
}
