import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "./api.service";

@Injectable({
  providedIn: 'root'
})
export class PlatanService {

  private PLATAN_PATH = "/platan/";

  constructor(private apiService: ApiService) { }


  basvuruIcinTCKontroluYap(kullanici: any): Observable<any> {
    return this.apiService.post(this.PLATAN_PATH, kullanici).pipe(
      res => {
        if (res) {

          //console.log('result var');
          //console.log(res);
          return res;
        } else {
          
          //console.log('result yok :');
          //console.log(res);
          return res;
        }
      }
    );
  }
}
