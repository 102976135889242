
import { BasvuruBilgileriDto } from "./basvuru-bilgileri.model";

export class OdemeBilgileriDto {
    basvuruBilgileriDto: BasvuruBilgileriDto;
    krediKartiNumarasi:string;
    cvv:string;
    sonKullanmaTarihiYil:string;
    sonKullanmaTarihiAy:string;
    posTipi:string;
    bankaSecimi:string;

    public constructor() {
        this.basvuruBilgileriDto = new BasvuruBilgileriDto();
    }
}
